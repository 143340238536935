import { call, put } from 'redux-saga/effects';
import config from 'config';
import axios from 'axios';
import posthog from 'posthog-js';

import { awsLogout } from '../crud/auth.crud';
import { sentry } from './sentry';
import { actions } from '../store/ducks/auth/actions';
import { getJwt } from '../crud/auth.crud';
import store from '../store/store';

var stringify = require('qs-stringify');

let axiosApi = axios.create({
  baseURL: config.api.SERVER_URL,
});

const MAX_TOTAL_RETRIES = 3;

export const doApiCallWithRetry = async (
  apiCallFn,
  maxRetries = MAX_TOTAL_RETRIES,
  retryCount = 0,
) => {
  try {
    const result = await apiCallFn();
    return { success: true, data: result };
  } catch (err) {
    const initialDelay = 200;
    // we only want to retry if the error is a connection error
    if (retryCount < maxRetries && isConnectionError(err)) {
      const exponentialDelay = initialDelay * Math.pow(2, retryCount);
      const jitter = Math.random() * 200;
      const delay = exponentialDelay + jitter;
      await new Promise((resolve) => setTimeout(resolve, delay));
      return doApiCallWithRetry(apiCallFn, maxRetries, retryCount + 1);
    }
    if (isConnectionError(err)) {
      store.dispatch(actions.setConnectionError(true));
      store.dispatch(actions.updateRequestError(true));
      sentry.captureException(err, {
        error: err,
      });
    }
    return { success: false, error: err };
  }
};

export function* postRequestWrapper(path, token, requestPayload) {
  let error = false;
  let setXPlan = false;
  let planHasExpired = false;
  let errorMessage = false;
  const xPlan = localStorage.getItem('x-plan') || '';

  const apiCallFn = async () => {
    const response = await axiosApi.post(path, stringify(requestPayload), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        'x-plan': xPlan,
        'x-parent-user-id': localStorage.getItem('x-parent-user-id') || '',
        'x-parent-user-email':
          localStorage.getItem('x-parent-user-email') || '',
      },
    });

    // Access header to get x-plan
    const headers = response.headers;
    if (headers['x-plan']) {
      localStorage.setItem('x-plan', headers['x-plan']);
      setXPlan = true;
    }
    if (headers['x-parent-user-id']) {
      localStorage.setItem('x-parent-user-id', headers['x-parent-user-id']);
      localStorage.setItem('ownAccount', 'disabled');
    }
    if (headers['x-parent-user-email']) {
      localStorage.setItem(
        'x-parent-user-email',
        headers['x-parent-user-email'],
      );
      store.dispatch(actions.setParentEmail(headers['x-parent-user-email']));
    }

    const status = response.status;
    if (status === 401) {
      awsLogout();
      localStorage.clear();
      posthog.reset();
      window.location.replace('/');
    }

    return response.data;
  };

  const result = yield call(() => doApiCallWithRetry(apiCallFn));

  if (!result.success) {
    error = true;
    sentry.captureException(result.error, {
      payload: requestPayload,
      error: result.error,
    });
  } else {
    const data = result.data;
    if (
      [
        'Please choose a plan.',
        'Please contact the account owner to renew the subscription.',
      ].includes(data)
    ) {
      planHasExpired = true;
      errorMessage = data;
    }
    if (
      data &&
      [
        `You do not have permission to access this resource. Please to contact the account owner if you want access.`,
        `You aren't connected to this account. Please to contact the account owner if you want access again.`,
      ].includes(data.message)
    ) {
      store.dispatch(actions.setAccessDeniedError(data.message));
    }
    if (!data.responseHasData && data.responseDataLocation) {
      return waitForResponseData(data.responseDataLocation);
    }
    store.dispatch(actions.setConnectionError(false));
  }

  if (error) {
    if (path !== '/latest-date') {
      yield put(actions.updateRequestError(true));
    }
  }
  if (setXPlan) {
    yield put(actions.setUserPlan());
  }
  if (planHasExpired) {
    yield put(actions.setExpiredPlanError(errorMessage));
    return null;
  }
  return result.data;
}

async function waitForResponseData(responseDataLocation) {
  let waitSeconds = 5;
  const maxWaitSeconds = 15;
  const start = new Date();
  const TEN_MINUTES = 1000 * 60 * 10;
  let lastError;
  while (new Date() - start < TEN_MINUTES) {
    try {
      const response = await fetch(responseDataLocation);
      if (response.status !== 403) {
        return await response.json();
      }
    } catch (e) {
      lastError = e;
      console.log(e);
    }
    // eslint-disable-next-line
    await new Promise((resolve) => setTimeout(resolve, 1000 * waitSeconds));
    if (waitSeconds < maxWaitSeconds) {
      waitSeconds++;
    }
  }

  sentry.captureException(lastError, {
    message: 'Request timed out',
    lastError,
    responseDataLocation,
  });

  throw new Error('Request timed out');
}

export const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

// Add this helper function to check for connection-related errors
const isConnectionError = (error) => {
  if (!error) return false;

  // Check for Connection terminated message
  if (
    error.message?.includes('Connection terminated') ||
    error.error?.includes('Connection terminated') ||
    error?.response?.data?.message?.includes('Connection terminated') ||
    error?.response?.data?.error?.includes('Connection terminated')
  ) {
    return true;
  }

  // Check for Network Error
  if (error.message === 'Network Error') {
    return true;
  }

  return false;
};

// Modify behaviorAfterRequest function
const behaviorAfterRequest = async (resp) => {
  store.dispatch(actions.setConnectionError(false));
  if (resp.headers && resp.headers['x-plan']) {
    localStorage.setItem('x-plan', resp.headers['x-plan']);
    store.dispatch(actions.setUserPlan());
  }
  if (resp.headers && resp.headers['x-parent-user-id']) {
    localStorage.setItem('ownAccount', 'disabled');
    localStorage.setItem('x-parent-user-id', resp.headers['x-parent-user-id']);
  }
  if (resp.headers && resp.headers['x-parent-user-email']) {
    localStorage.setItem(
      'x-parent-user-email',
      resp.headers['x-parent-user-email'],
    );
    store.dispatch(actions.setParentEmail(resp.headers['x-parent-user-email']));
  }
  const status = resp.status;
  if (status === 401) {
    awsLogout();
    posthog.reset();
    localStorage.clear();
    window.location.replace('/');
  }
  const data = resp.data;
  if (
    [
      'Please choose a plan.',
      'Please contact the account owner to renew the subscription.',
    ].includes(data)
  ) {
    store.dispatch(actions.setExpiredPlanError(data));
    return null;
  }
  if (
    data &&
    [
      `You do not have permission to access this resource. Please to contact the account owner if you want access.`,
      `You aren't connected to this account. Please to contact the account owner if you want access again.`,
    ].includes(data.message)
  ) {
    store.dispatch(actions.setAccessDeniedError(data.message));
    return null;
  }
  if (!data.responseHasData && data.responseDataLocation) {
    return waitForResponseData(data.responseDataLocation);
  }

  return resp.data;
};

// Modify behaviorWhenError function
const behaviorWhenError = async (error, url) => {
  if (error.message === 'Request failed with status code 402') {
    const response = error.response || {};
    if (response.headers && response.headers['x-plan']) {
      localStorage.setItem('x-plan', response.headers['x-plan']);
      store.dispatch(actions.setUserPlan());
    }
    if (response.headers && response.headers['x-parent-user-id']) {
      localStorage.setItem(
        'x-parent-user-id',
        response.headers['x-parent-user-id'],
      );
      localStorage.setItem('ownAccount', 'disabled');
    }
    if (response.headers && response.headers['x-parent-user-email']) {
      localStorage.setItem(
        'x-parent-user-email',
        response.headers['x-parent-user-email'],
      );
      store.dispatch(
        actions.setParentEmail(response.headers['x-parent-user-email']),
      );
    }
    const data = response.data;
    if (
      [
        'Please choose a plan.',
        'Please contact the account owner to renew the subscription.',
      ].includes(data)
    ) {
      store.dispatch(actions.setExpiredPlanError(data));
      return null;
    }
    if (
      data &&
      [
        `You do not have permission to access this resource. Please to contact the account owner if you want access.`,
        `You aren't connected to this account. Please to contact the account owner if you want access again.`,
      ].includes(data.message)
    ) {
      store.dispatch(actions.setAccessDeniedError(data.message));
      return null;
    }
  }

  if (error.message === 'Request failed with status code 403') {
    const response = error.response || {};
    if (response.headers && response.headers['x-plan']) {
      localStorage.setItem('x-plan', response.headers['x-plan']);
      store.dispatch(actions.setUserPlan());
    }
    if (response.headers && response.headers['x-parent-user-id']) {
      localStorage.setItem(
        'x-parent-user-id',
        response.headers['x-parent-user-id'],
      );
      localStorage.setItem('ownAccount', 'disabled');
    }
    if (response.headers && response.headers['x-parent-user-email']) {
      localStorage.setItem(
        'x-parent-user-email',
        response.headers['x-parent-user-email'],
      );
      store.dispatch(
        actions.setParentEmail(response.headers['x-parent-user-email']),
      );
    }
    const data = response.data;
    if (
      [
        'Please choose a plan.',
        'Please contact the account owner to renew the subscription.',
      ].includes(data)
    ) {
      store.dispatch(actions.setExpiredPlanError(data));
      return null;
    }
    if (
      data &&
      [
        `You do not have permission to access this resource. Please to contact the account owner if you want access.`,
        `You aren't connected to this account. Please to contact the account owner if you want access again.`,
      ].includes(data.message)
    ) {
      store.dispatch(actions.setAccessDeniedError(data.message));
      return null;
    }
  }
  return error.response?.data;
};

export const getHeaders = async () => {
  const token = await getJwt();
  const xPlan = localStorage.getItem('x-plan') || '';
  const xParentUserId = localStorage.getItem('x-parent-user-id') || '';
  const xParentUserEmail = localStorage.getItem('x-parent-user-email') || '';
  return {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${token}`,
    'x-plan': xPlan,
    'x-parent-user-id': xParentUserId,
    'x-parent-user-email': xParentUserEmail,
  };
};

export const getHeadersNoAuth = async () => {
  return {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
};

export const request = (opts = {}) => {
  const defaultOptions = {
    headers: {
      ...opts,
    },
  };

  const makeRequest = async (method, url, data = null, options = {}) => {
    const executeRequest = async () => {
      const config = { ...defaultOptions, ...options };
      switch (method) {
        case 'get':
          return await axiosApi.get(url, config);
        case 'post':
          return await axiosApi.post(url, data, config);
        case 'put':
          return await axiosApi.put(url, data, config);
        case 'patch':
          return await axiosApi.patch(url, data, config);
        case 'delete':
          return await axiosApi.delete(url, config);
        default:
          throw new Error(`Unsupported method ${method}`);
      }
    };

    const result = await doApiCallWithRetry(executeRequest);

    if (result.success) {
      return behaviorAfterRequest(result.data);
    } else {
      return behaviorWhenError(result.error, url);
    }
  };

  return {
    get: (url, options = {}) => makeRequest('get', url, null, options),
    post: (url, data, options = {}) => makeRequest('post', url, data, options),
    put: (url, data, options = {}) => makeRequest('put', url, data, options),
    patch: (url, data, options = {}) =>
      makeRequest('patch', url, data, options),
    delete: (url, options = {}) => makeRequest('delete', url, null, options),
  };
};

export default request;
