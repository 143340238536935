import { useFeatureFlagEnabled } from 'posthog-js/react';

export enum POSTHOG_EVENTS {
  UserStartedKeywordSpySearch = 'UserStartedKeywordSpySearch',
  UserFinishedKeywordSpySearch = 'UserFinishedKeywordSpySearch',
  UserStartedASINsSearch = 'UserStartedASINsSearch',
  UserFinishedASINsSearch = 'UserFinishedASINsSearch',
  UserStartedLoadingProjectDetailsByASINs = 'UserStartedLoadingProjectDetailsByASINs',
  UserFinishedLoadingProjectDetailsByASINs = 'UserFinishedLoadingProjectDetailsByASINs',
  UserStartedLoadingProjectDetailsByKeywords = 'UserStartedLoadingProjectDetailsByKeywords',
  UserFinishedLoadingProjectDetailsByKeywords = 'UserFinishedLoadingProjectDetailsByKeywords',
  UserStartedLoadingProjectDetailsByRanks = 'UserStartedLoadingProjectDetailsByRanks',
  UserFinishedLoadingProjectDetailsByRanks = 'UserFinishedLoadingProjectDetailsByRanks',
  UserStartedLoadingProjectDetailsByTrend = 'UserStartedLoadingProjectDetailsByTrend',
  UserFinishedLoadingProjectDetailsByTrend = 'UserFinishedLoadingProjectDetailsByTrend',
}

export const useIsNoteFeatureEnabled = () => {
  return useFeatureFlagEnabled('notes-feature');
};

export const useIsSwitchAccountButtonFeatureEnabled = () => {
  return useFeatureFlagEnabled('switch-account-button');
};

export const useIsRankKeywordTabFeatureEnabled = () => {
  return useFeatureFlagEnabled('rank-by-keyword-tab-feature');
};

export const useIsRankBSRTabFeatureEnabled = () => {
  return useFeatureFlagEnabled('rank-bsr-tab-feature');
};

export const useIsRankCurrentTabFeatureEnabled = () => {
  return useFeatureFlagEnabled('current-rank-tab-feature');
};

export const useIsRankDataByIntervalRangeEnabled = () => {
  return useFeatureFlagEnabled('rank-data-by-interval-range');
};

export const useIsTeamMenuFeatureEnabled = () => {
  return useFeatureFlagEnabled('team-menu-feature');
};

export const useIsSuggestKeywordFeatureEnabled = () => {
  return useFeatureFlagEnabled('suggest-keyword-feature');
};

export const useIsSuggestASINFeatureEnabled = () => {
  return useFeatureFlagEnabled('suggest-asin-feature');
};

export const useIsSettingConnectionMenuFeatureEnabled = () => {
  return useFeatureFlagEnabled('setting-connection-menu');
};

export const useIsGoogleSheetButtonFeatureEnabled = () => {
  return useFeatureFlagEnabled('google-sheet-button');
};
